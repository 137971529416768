<template>
  <div>
    <general-table
      :api-url="APIURL"
      :add-type="addType"
      :columns="columns"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :delete-content="false"
      :block-content="false"
      :edit-content="true"
      :viw-component="viwComponent"
      :view-content="false"
      :search-box="false"
      :type="type"
      :selectable="false"
    >
      <template #cell(name)="data">
        {{ data.item.name_ar }}
      </template>

      <template #cell(action)="data">
        <router-link :to="{ name: 'edit-campaign-tier', query: { id: data.item.id }, params:{id:$route.params.id}}">
          <span
            title="Edit section"
          >
            <feather-icon
              icon="EditIcon"
              class="cursor-pointer"
            />
          </span>
        </router-link>

      </template>
      <template
        #cell(is_active)="data"
      >
        <b-badge
          class="change-status"
          :variant="changeVaraint(data.item.is_active)"
        >
          {{ getTypes(data.item.is_active) }}
        </b-badge>
      </template>
    </general-table>

  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      addType: 'Add New Tier',
      type: 'page',
      urlType: 'campaigns',
      addComponentName: 'add-campaign-tier',
      editComponent: 'edit-campaign-tier',
      viwComponent: 'show-campaign',
      columns: [
        { key: 'id' },
        { key: 'name_en', label: 'Name' },
        { key: 'range_start', label: 'Range Start' },
        { key: 'range_end', label: 'Range End' },

        { key: 'action' },
      ],
    }
  },
  computed: {
    APIURL() {
      return `admin/tiers?product_gift_id=${this.$route.params.id}`
    },
  },
  methods: {
    changeVaraint(type) {
      // eslint-disable-next-line eqeqeq
      if (type === 1) return 'success'

      return 'danger'
    },

    getTypes(type) {
      if (type === 1) {
        return 'Displayed'
      } if (type === 0) {
        return 'Hidden'
      }
      return 'Displayed'
    },

  },
}
</script>

  <style>

  </style>
